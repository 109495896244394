import { TZDate } from '@date-fns/tz';
import { format } from 'date-fns';
import { LOCALES } from 'mda2-frontend/src/constants';
import useStore from 'model/store';

const formatDate = (date: Date, formatStr = 'PP', utc = false) => {
  // From https://date-fns.org/v2.21.3/docs/I18n
  const localeId = useStore.getState().userSettings.language;

  try {
    return utc
      ? format(new TZDate(date, 'UTC'), formatStr, {
          locale: LOCALES[localeId === 'en' ? 'enGB' : 'de'], // avoid US date format
        })
      : format(date, formatStr, {
          locale: LOCALES[localeId === 'en' ? 'enGB' : 'de'], // avoid US date format
        });
  } catch (e) {
    console.error("Couldn't format date", e);

    return '-';
  }
};

export default formatDate;
