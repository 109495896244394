import { DeviceTypes } from '@/common/types';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Transition from '@/generic/components/Transition';
import { MqttSystems } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from '@/utils/parseBluerangeTopic';
import parseMda2Topic from '@/utils/parseMda2Topic';
import type { Row } from '@tanstack/react-table';
import type { LiveBeaconsQueryData } from '../RemoveBeaconModal/RemoveBeaconModal';
import BeaconMap from './components/BeaconMap';
import BluerangeMqttAction from './components/BluerangeMqttAction';
import Mda2MqttAction from './components/Mda2MqttAction';
import MqttStatus from './components/MqttStatus';
import SensorsStatus from './components/SensorsStatus';
import StatusChart from './components/StatusChart';

export default function RowSubComponent(row: Row<LiveBeaconsQueryData>) {
  return (
    <div className="px-6 py-2">
      <div className="h-48 max-width-screen">
        <StatusChart beaconName={row.original.Name} />
      </div>
      <SensorsStatus beaconName={row.original.Name} />
      <Transition
        show={
          typeof row.original.Floor?.Number === 'number' &&
          !!row.original.Floor.Building.Name
        }
      >
        <div className="max-width-screen">
          <BeaconMap
            data={row.original}
            floorNumber={row.original.Floor?.Number ?? 0}
            buildingName={row.original.Floor?.Building.Name ?? ''}
          />
        </div>
      </Transition>
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
        <>
          <Transition
            show={row.original.MqttBeaconSource.Name === MqttSystems.Bluerange}
          >
            <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
              <MqttStatus
                mqttTopic={parseBluerangeTopic(row.original.MqttTopic)}
                mqttSystem={MqttSystems.Bluerange}
              />
            </PrivateWrapper>
            <Transition
              show={row.original.DeviceType?.Name !== DeviceTypes.GATEWAY}
            >
              <BluerangeMqttAction
                mqttTopic={parseBluerangeTopic(row.original.MqttTopic)}
              />
            </Transition>
          </Transition>
          <Transition
            show={row.original.MqttBeaconSource.Name === MqttSystems.Mda2}
          >
            <Mda2MqttAction
              mqttTopic={`cmd/${parseMda2Topic(
                row.original.MqttTopic,
              )}/identify`}
            />
          </Transition>
        </>
      </PrivateWrapper>
    </div>
  );
}
