import { useIntl } from 'translations/Intl';

import Card from 'mda2-frontend/src/generic/components/Card';
import LivePing from 'mda2-frontend/src/generic/components/LivePing';
import Subtitle from 'mda2-frontend/src/generic/components/Subtitle';
import useStore from 'mda2-frontend/src/model/store';

import ClimateMap from './components/ClimateMap';

export default function ClimateView(): JSX.Element {
  const intl = useIntl();
  const floor = useStore((state) => state.userSettings.floor);
  const building = useStore((state) => state.userSettings.building);

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-2">
        <Card className="relative" fullScreenButton>
          <div className="flex items-center justify-between">
            <div className="flex space-x-3">
              <LivePing />
              <Subtitle
                value={intl.formatMessage(
                  {
                    id: 'Building Floor',
                  },
                  { number: floor?.Number, building: building?.Name },
                )}
              />
            </div>
          </div>
          <ClimateMap />
        </Card>
      </div>
    </div>
  );
}
