import type { Mda2ConfigurationType } from 'common/types';
import Checkbox from 'generic/components/Form/Checkbox';
import Select from 'generic/components/Form/Select/Select';
import {
  type Mda2FirmwarePackageConfigurationQuery,
  type Mda2SensorPolicyConfigurationQuery,
  useMda2FirmwarePackageConfigurationQuery,
  useMda2SensorPolicyConfigurationQuery,
} from 'graphql/types';
import useStore from 'model/store';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

interface Mda2ConfigurationProps {
  setConfiguration: React.Dispatch<
    React.SetStateAction<Map<string, Mda2ConfigurationType>>
  >;
  setValid?: (valid: boolean) => void;
  organization: string;
}

function LoadingSkeleton() {
  return (
    <div className="w-full animate-pulse flex flex-col space-y-2">
      <div className="w-12 bg-neutral-200 h-4 rounded-md" />
      <div className="w-full bg-neutral-200 h-4 rounded-md" />
    </div>
  );
}

export function Mda2SensorPolicyConfiguration({
  setConfiguration,
  setValid,
  organization,
}: Mda2ConfigurationProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;
  const hasuraHeader = useHasuraHeader();
  const [selectedPolicy, setSelectedPolicy] = useState<
    Mda2SensorPolicyConfigurationQuery['SensorPolicies'][number] | undefined
  >(undefined);

  const [{ data: mda2Config, fetching }] =
    useMda2SensorPolicyConfigurationQuery({
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_ADMIN,
            ['SensorPolicies'],
          ),
        [hasuraHeader, userRoles],
      ),
      variables: {
        Organization: organization,
      },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: setConfiguration changes on every render and we only need it for callback
  useEffect(() => {
    if (selectedPolicy) {
      setConfiguration(
        (prev) =>
          new Map([
            ...prev,
            [
              organization,
              {
                ...prev.get(organization),
                ...{
                  sensorPolicyId: selectedPolicy.Id,
                },
              },
            ],
          ]),
      );
    }
  }, [selectedPolicy]);

  // Set initial policy
  useEffect(() => {
    if (!selectedPolicy && mda2Config?.SensorPolicies) {
      setSelectedPolicy(mda2Config.SensorPolicies.find((p) => p.Default));
    }
  }, [mda2Config?.SensorPolicies, selectedPolicy]);

  useEffect(() => {
    if (selectedPolicy && setValid) {
      setValid(true);
    }
  }, [selectedPolicy, setValid]);

  return (
    <div className="flex flex-col space-y-2">
      {fetching && <LoadingSkeleton />}
      <div>
        {mda2Config?.SensorPolicies.length && (
          <Select
            dataTestId="policy-select"
            label="Sensor policy"
            value={selectedPolicy}
            options={mda2Config.SensorPolicies}
            onChangeSelected={(selected) =>
              selected && setSelectedPolicy(selected)
            }
            renderValue={(s) =>
              `${s?.Name} ${
                s?.Default
                  ? `(${intl.formatMessage({ id: 'Default policy' })})`
                  : ''
              }`
            }
            keyParameter="Id"
            required
            isDeselectable={false}
          />
        )}
      </div>
    </div>
  );
}

export function Mda2FirmwarePackageConfiguration({
  setConfiguration,
  setValid,
  organization,
}: Mda2ConfigurationProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [excludedFromUpdates, setExcludedFromUpdates] = useState(false);
  const [selectedFirmwarePackage, setSelectedFirmwarePackage] = useState<
    | Exclude<
        Mda2FirmwarePackageConfigurationQuery['FirmwarePackages'],
        undefined
      >[number]
    | undefined
  >(undefined);

  const [{ data: mda2Config, fetching }] =
    useMda2FirmwarePackageConfigurationQuery({
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.READ_ALL, ['FirmwarePackages']),
        [hasuraHeader],
      ),
      variables: {
        Organization: organization,
      },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: setConfiguration changes on every render and we only need it for callback
  useEffect(() => {
    if (selectedFirmwarePackage?.Id) {
      setConfiguration(
        (prev) =>
          new Map([
            ...prev,
            [
              organization,
              {
                ...prev.get(organization),
                ...{
                  excludedFromUpdates,
                  firmwarePackageId: selectedFirmwarePackage.Id,
                },
              },
            ],
          ]),
      );
    }
  }, [selectedFirmwarePackage, selectedFirmwarePackage?.Id]);

  // Set initial firmware package
  useEffect(() => {
    if (!selectedFirmwarePackage && mda2Config?.FirmwarePackages) {
      setSelectedFirmwarePackage(
        mda2Config.FirmwarePackages.find((p) =>
          p.FirmwarePackageOrganizations.find((f) => f.Latest),
        ),
      );
    }
  }, [mda2Config?.FirmwarePackages, selectedFirmwarePackage]);

  useEffect(() => {
    if (selectedFirmwarePackage && setValid) {
      setValid(true);
    }
  }, [selectedFirmwarePackage, setValid]);

  return (
    <div className="flex flex-col space-y-2">
      {fetching && <LoadingSkeleton />}
      <div className="space-y-1">
        {mda2Config?.FirmwarePackages?.length && (
          <Select
            label="Firmware package"
            value={selectedFirmwarePackage}
            options={mda2Config.FirmwarePackages}
            onChangeSelected={(selected) =>
              selected && setSelectedFirmwarePackage(selected)
            }
            renderValue={(s) =>
              `${s?.Version} ${
                s?.FirmwarePackageOrganizations.find((f) => f.Latest)
                  ? `(${intl.formatMessage({ id: 'Latest version' })})`
                  : ''
              }`
            }
            keyParameter="Id"
            required
            isDeselectable={false}
          />
        )}
        <Checkbox
          checked={excludedFromUpdates}
          setChecked={(excluded) => {
            setExcludedFromUpdates(excluded);
            setConfiguration(
              (prev) =>
                new Map([
                  ...prev,
                  [
                    organization,
                    {
                      ...prev.get(organization),
                      ...{
                        excludedFromUpdates: excluded,
                      },
                    },
                  ],
                ]),
            );
          }}
          label={intl.formatMessage({ id: 'Exclude from automatic updates' })}
        />
      </div>
    </div>
  );
}
