import Pill from '@/generic/components/Pill';
import OnlineCell from '@/generic/components/Table/components/OnlineCell';
import type { CellContext } from '@tanstack/react-table';
import Tooltip from 'generic/components/Tooltip/Tooltip';
import {
  type AllMqttAssetTrackersQuery,
  useMqttAssetTrackersNameMutation,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useState } from 'react';
import {
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
} from 'react-icons/fa';
import { HiCheck, HiOutlinePencil, HiOutlineXMark } from 'react-icons/hi2';

export type AssetData = AllMqttAssetTrackersQuery['MqttAssetTrackers'][number];

export function StatusCell({ cell }: CellContext<AssetData, unknown>) {
  const { IsOffline } = cell.row.original;

  return <OnlineCell status={IsOffline ? 'Offline' : 'Online'} />;
}

export function DisplayNameCell({ row }: CellContext<AssetData, unknown>) {
  const userRoles = useStore((state) => state.user)?.roles;
  const [value, setValue] = useState<string>(row.original.DisplayName ?? '-');
  const [isEditing, setIsEditing] = useState(false);
  const [, updateDisplayName] = useMqttAssetTrackersNameMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const onClick = () => {
    if (value !== row.original.DisplayName) {
      updateDisplayName(
        {
          UniqueIdentifier: row.original.UniqueIdentifier,
          DisplayName: value === '' ? null : value,
        },
        hasuraHeader(HasuraPermissions.WRITE_MQTTASSETTRACKER),
      ).then((data) => {
        if (data.error) {
          setValue('');
        }
        toast(data);
      });
      setIsEditing(false);
    }
  };

  return !row.getIsGrouped() &&
    userRoles?.includes(HasuraPermissions.WRITE_MQTTASSETTRACKER) ? (
    <div className="flex justify-between items-center w-full">
      {isEditing ? (
        <input
          data-test-id="input-asset-name"
          className="dark:bg-neutral-800 w-full mr-1 border -my-1 px-1 border-primary-500 rounded"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      ) : (
        <span>{value === '' ? '-' : value}</span>
      )}
      {!isEditing ? (
        <HiOutlinePencil
          data-test-id="edit-asset-name"
          className="size-4 cursor-pointer"
          onClick={() => setIsEditing(!isEditing)}
        />
      ) : (
        <div className="flex">
          <HiOutlineXMark
            className="size-4 cursor-pointer"
            onClick={() => {
              setIsEditing(false);
              setValue(row.original.DisplayName ?? '');
            }}
          />
          {value !== row.original.DisplayName && (
            <HiCheck
              data-test-id="save-asset-name"
              className="size-4 cursor-pointer"
              onClick={onClick}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    value
  );
}

export function BatteryCell(
  props: CellContext<AssetData, unknown>,
): JSX.Element | string {
  const { row } = props;
  const { BatteryVoltage } = row.original;

  if (!BatteryVoltage) {
    return '-';
  }

  if (BatteryVoltage === 3) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryFull className="size-5 text-green-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.75) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryThreeQuarters className="size-5 text-green-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.5) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryHalf className="size-5 text-orange-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage >= 2.2) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryQuarter className="size-5 text-orange-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  if (BatteryVoltage < 2.2) {
    return (
      <Tooltip
        content={
          <p>
            <FaBatteryEmpty className="size-5 text-red-500" />
          </p>
        }
      >
        {BatteryVoltage} Volt
      </Tooltip>
    );
  }

  return '-';
}

export function AccuracyCell(props: CellContext<AssetData, unknown>) {
  const { Accuracy } = props.row.original;
  return (
    <Pill
      className={`
          ${
            Accuracy < 150
              ? '!bg-green-500 !text-green-100'
              : Accuracy < 250
                ? '!bg-orange-500 !text-orange-100'
                : '!bg-red-500 !text-red-100'
          }
        `}
    >
      &plusmn;{(Accuracy / 100).toFixed(2)}m
    </Pill>
  );
}
