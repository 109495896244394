import parseMda2Topic from '@/utils/parseMda2Topic';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import type {
  Mda2MessageData,
  MqttMessage,
} from 'generic/components/MqttListener/MqttListener';
import Tooltip from 'generic/components/Tooltip';
import { useDeviceEnrollmentStatusQuery } from 'graphql/types';
import useStore from 'model/store';
import { useEffect, useMemo } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

export enum Mda2MessageType {
  BIRTH = 'birth',
  DEATH = 'death',
  DATA = 'data',
  META = 'meta',
  ALERT = 'alert',
  ACK = 'ack',
  SYNC = 'sync',
  CMD = 'cmd',
  ADMINCMD = 'admincmd',
}

export const isMda2Topic = (topic: string) => topic.startsWith('mda2/');

export const getBeaconId = (topic: string) =>
  topic
    .split('/')
    .find((m) => /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(m));

export const generateMda2Topic = (
  topic: string,
  messageType: Mda2MessageType,
) => `mda2/${topic.split('/')[1]}/${messageType}/${getBeaconId(topic)}`;

export function isMda2Message(
  feature: MqttMessage['message'],
): feature is Mda2MessageData {
  return typeof feature !== 'string';
}

export default function TopicCell({
  topic,
  setupDevice,
  fetching,
  selectedBeacons,
  setDisabledBeacons,
  message,
}: {
  topic: MqttMessage['topic'];
  message: MqttMessage['message'];
  setupDevice: (selectedDevices: string[]) => void;
  fetching: boolean;
  selectedBeacons: string[];
  setDisabledBeacons: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;

  const isMda2 = isMda2Message(message);

  const [{ data: beaconData, fetching: loadingEnrollmentStatus }] =
    useDeviceEnrollmentStatusQuery({
      variables: {
        UserInput: { macAddress: getBeaconId(topic) ?? '' },
        IsMda2: isMda2,
        BeaconName: getBeaconId(topic),
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        [hasuraHeader],
      ),
      requestPolicy: 'cache-and-network',
    });

  const version = isMda2
    ? // It only shows firmware that is available in firmware packages
      // If there is no firmware package, the version will be undefined
      (beaconData?.MqttBeaconFirmware?.[0]?.Firmware.Version ??
      intl.formatMessage({ id: 'Not available' }))
    : message;

  const isDeviceEnrolled = beaconData?.GetMqttDeviceEnrollmentStatus.enrolled;
  const sameOrganization =
    beaconData?.GetMqttDeviceEnrollmentStatus.sameOrganization;

  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const operatorNumber = isMda2
    ? message.enrollmentInfo?.enrollerId
    : Number.parseInt(topic.split('/')[2] ?? '0', 10);

  // In order to quickly distinguish between the operators (the person doing the enrollment)
  // we choose different colors per operator
  const getOperatorColor = () => {
    switch (operatorNumber) {
      case 1:
        return 'bg-primary-200 text-primary-500';

      case 2:
        return 'bg-green-200 text-green-500';

      case 3:
        return 'bg-blue-200 text-blue-500';

      case 4:
        return 'bg-yellow-200 text-yellow-500';

      default:
        break;
    }

    return 'bg-neutral-200 text-white';
  };

  const enrollmentMessage = useMemo(() => {
    if (isDeviceEnrolled) {
      if (sameOrganization) {
        return 'Already enrolled in same organization';
      }
      return 'Already enrolled in other organization';
    }
    if (enrollmentConfiguration) {
      return 'Send configuration';
    }
    return 'Set enrollment configuration first';
  }, [isDeviceEnrolled, sameOrganization, enrollmentConfiguration]);

  const disabled =
    !enrollmentConfiguration ||
    !enrollmentConfiguration.configuration?.sensorPolicyId ||
    isDeviceEnrolled ||
    // Wait for the enrollment status to load
    loadingEnrollmentStatus;

  useEffect(() => {
    if (disabled) {
      setDisabledBeacons((existing) =>
        existing.includes(topic) ? existing : [...existing, topic],
      );
    } else {
      setDisabledBeacons((existing) =>
        existing.includes(topic)
          ? existing.filter((e) => !e.includes(topic))
          : existing,
      );
    }
  }, [disabled, setDisabledBeacons, topic]);

  return (
    <div className="flex space-x-2 items-center">
      <Tooltip
        content={
          <p>
            <StyledButton
              disabled={disabled}
              data-test-id="configure-button"
              className={`${
                isDeviceEnrolled
                  ? '!bg-red-100 disabled:text-red-400 disabled:hover:text-red-400 hover:bg-red-300 disabled:hover:bg-red-100 dark:bg-red-100 dark:hover:bg-red-300 dark:disabled:hover:bg-red-100'
                  : '!bg-green-100 disabled:text-green-400 disabled:hover:text-green-400 hover:bg-green-300 disabled:hover:bg-green-100 dark:bg-green-100 dark:hover:bg-green-300 dark:disabled:hover:bg-green-100'
              } `}
              onClick={() =>
                setupDevice([
                  isMda2
                    ? `mda2/unconfigured/admincmd/${getBeaconId(topic)}`
                    : `cmd/unconfigured/${parseMda2Topic(topic)}/configure`,
                ])
              }
            >
              {(fetching &&
                selectedBeacons.find((beacon) =>
                  beacon.includes(getBeaconId(topic) ?? ''),
                )) ||
              loadingEnrollmentStatus ? (
                <FaCircleNotch className="animate-spin size-5 text-neutral-200" />
              ) : (
                <HiOutlineWrenchScrewdriver
                  className={`size-5 ${
                    isDeviceEnrolled ? 'text-red-500' : 'text-green-500'
                  }`}
                />
              )}
            </StyledButton>
          </p>
        }
      >
        <FormattedMessage id={enrollmentMessage} />
      </Tooltip>
      <div className="flex items-center justify-between w-full space-x-2">
        <div className="flex flex-col">
          {userRoles?.includes(HasuraPermissions.VIEW_STATUS) &&
          sameOrganization ? (
            <Link
              className="hover:text-primary-500 text-sm"
              to={`/status?beacon=${getBeaconId(topic)}`}
              data-test-id={`button-id-${getBeaconId(topic)}`}
            >
              {getBeaconId(topic)}
            </Link>
          ) : (
            <div
              className="text-sm"
              data-test-id={`button-id-${getBeaconId(topic)}`}
            >
              {getBeaconId(topic)}
            </div>
          )}
          <div className="flex text-xs">
            <FormattedMessage id="Version" />:{' '}
            <div
              className={`${
                version === '1.0.1' ? 'text-red-500' : ''
              } flex pl-1`}
            >
              {version}{' '}
              {version === '1.0.1' && (
                <Tooltip>
                  <FormattedMessage id="Version problem 1.0.1" />
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <Tooltip
          content={
            <div
              className={`${getOperatorColor()} size-6 focus:outline-none rounded-full flex items-center justify-center select-none capitalize`}
            >
              {operatorNumber}
            </div>
          }
        >
          <FormattedMessage id="Operator" /> {operatorNumber}
        </Tooltip>
      </div>
    </div>
  );
}
