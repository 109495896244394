import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title/Title';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

import {
  HiMiniServerStack,
  HiOutlineBuildingOffice2,
  HiOutlineCog6Tooth,
  HiOutlineCog8Tooth,
  HiOutlineCpuChip,
  HiOutlineRocketLaunch,
  HiOutlineUser,
  HiOutlineUserGroup,
} from 'react-icons/hi2';

export default function SettingsView() {
  return (
    <div>
      <Title value="Settings" />
      <Tab
        tabs={[
          {
            end: true, // avoid to be always active for all routes
            path: '',
            userRole: HasuraPermissions.READ,
            name: 'Personal',
            icon: <HiOutlineCog6Tooth className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'organization',
            userRole: HasuraPermissions.WRITE_ORGANIZATION,
            name: 'Organization',
            icon: <HiOutlineCog8Tooth className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'mapping',
            userRole: HasuraPermissions.WRITE_MQTTMAPPING,
            name: 'External devices',
            icon: <HiMiniServerStack className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'sensors',
            userRole: [
              HasuraPermissions.WRITE_ORGANIZATION,
              HasuraPermissions.MDA2,
            ],
            name: 'Sensor policies',
            icon: <HiOutlineCpuChip className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'firmwares',
            userRole: HasuraPermissions.READ_ALL,
            name: 'Firmwares',
            icon: <HiOutlineRocketLaunch className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'user',
            end: true, // avoid to be always active for all routes
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Users',
            icon: <HiOutlineUser className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'group',
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Groups',
            icon: <HiOutlineUserGroup className="md:h-5 md:w-5 size-6" />,
          },
          {
            path: 'organizations',
            userRole: HasuraPermissions.VIEW_ORGANIZATIONS,
            name: 'Organizations',
            icon: <HiOutlineBuildingOffice2 className="md:h-5 md:w-5 size-6" />,
          },
        ]}
      />
    </div>
  );
}
