import SensorsStatus from '@/pages/StatusView/components/RowSubComponent/components/SensorsStatus';
import type { ColumnDef } from '@tanstack/react-table';
import Table from 'generic/components/Table/Table';
import { useAllMqttAssetTrackersQuery } from 'mda2-frontend/src/graphql/types';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useDecodedLocation from 'utils/useDecodedLocation';
import AssetHistory from './components/AssetHistory';
import {
  AccuracyCell,
  type AssetData,
  BatteryCell,
  DisplayNameCell,
  StatusCell,
} from './components/Cells';

export default function AssetList() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [{ data: mqttAssetTrackers, fetching: loading }, reexecuteQuery] =
    useAllMqttAssetTrackersQuery({
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_FINDMYASSET),
        [hasuraHeader],
      ),
    });

  usePolling(loading, reexecuteQuery, 5000);

  const assetName = useDecodedLocation('asset');

  const defaultColumns: ColumnDef<AssetData>[] = [
    {
      id: 'building',
      header: intl.formatMessage({ id: 'Building' }),
      accessorFn: (row) => row.MqttGateway?.Floor?.Building.Name ?? '-',
    },
    {
      id: 'floor',
      header: intl.formatMessage({ id: 'Floor' }),
      accessorFn: (row) => row.MqttGateway?.Floor?.Number ?? 0,
    },
    {
      id: 'room',
      header: intl.formatMessage({ id: 'Room' }),
      accessorFn: (row) => row.Room?.Name ?? '-',
    },
    {
      id: 'batteryVoltage',
      header: intl.formatMessage({ id: 'Battery' }),
      accessorFn: (row) => row.BatteryVoltage,
      cell: BatteryCell,
    },
    {
      id: 'displayName',
      header: intl.formatMessage({ id: 'DisplayName' }),
      accessorFn: (row) => row.DisplayName ?? '-',
      cell: DisplayNameCell,
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'Name' }),
      accessorKey: 'Name',
    },
    {
      id: 'xCoordinate',
      header: intl.formatMessage({ id: 'XCoordinate' }),
      accessorFn: (row) => row.XCoordinate,
      accessorKey: 'XCoordinate',
    },
    {
      id: 'yCoordinate',
      header: intl.formatMessage({ id: 'YCoordinate' }),
      accessorFn: (row) => row.YCoordinate,
      accessorKey: 'YCoordinate',
    },
    {
      id: 'isMoving',
      header: intl.formatMessage({ id: 'IsMoving' }),
      accessorFn: (row) =>
        row.IsMoving
          ? intl.formatMessage({ id: 'Moving' })
          : intl.formatMessage({ id: 'Stationary' }),
    },
    {
      id: 'accuracy',
      header: intl.formatMessage({ id: 'Accuracy' }),
      accessorFn: (row) => row.Accuracy,
      accessorKey: 'Accuracy',
      cell: AccuracyCell,
    },
    {
      id: 'uniqueIdentifier',
      header: intl.formatMessage({ id: 'UniqueIdentifier' }),
      accessorKey: 'UniqueIdentifier',
    },
    {
      header: intl.formatMessage({ id: 'Status' }),
      id: 'Status',
      accessorFn: (row) =>
        row.IsOffline
          ? intl.formatMessage({ id: 'Offline' })
          : intl.formatMessage({ id: 'Online' }),
      cell: StatusCell,
    },
    {
      id: 'updatedAt',
      header: intl.formatMessage({ id: 'UpdatedAt' }),
      accessorFn: (row) => new Date(row.UpdatedAt),
      cell: ({ row }) =>
        formattedDistance(new Date(row.original.UpdatedAt), {
          includeSeconds: true,
        }),
      enableColumnFilter: false,
    },
  ];

  return (
    <div className="relative h-full">
      <Table<AssetData>
        id="assetlist"
        columns={defaultColumns}
        data={mqttAssetTrackers?.MqttAssetTrackers ?? []}
        loading={loading}
        getRowId={(row) => row.Id.toString()}
        initialState={{
          columnVisibility: {
            uniqueIdentifier: false,
            xCoordinate: false,
            yCoordinate: false,
            accuracy: false,
          },
          columnFilters: assetName
            ? [{ id: 'name', value: assetName }]
            : undefined,
        }}
        renderRowSubComponent={{
          render: (row) => (
            <div className="p-6 space-y-2">
              <AssetHistory assetData={row.original} />

              <SensorsStatus beaconName={row.original.MqttBeacon?.Name ?? ''} />
            </div>
          ),
          expanderColumnId: 'name',
        }}
        key={assetName}
      />
    </div>
  );
}
