export enum Color {
  GREEN = 'green',
}

interface LivePingProps {
  color?: Color;
}

function LivePing({ color }: LivePingProps) {
  return (
    <div className="flex items-center justify-center">
      <span
        className={`animate-ping absolute inline-flex size-4 rounded-full ${
          color ? `bg-${color}-400` : 'bg-primary-400'
        } opacity-75`}
      />
      <span
        className={`relative inline-flex rounded-full size-3 ${
          color ? `bg-${color}-500` : 'bg-primary-500'
        }`}
      />
    </div>
  );
}

export default LivePing;
