import type { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Table, { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  type OrganizationsQuery,
  useOrganizationsQuery,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useDecodedLocation from 'mda2-frontend/src/utils/useDecodedLocation';
import useStore from 'model/store';
import { useMemo, useState } from 'react';
import {
  HiOutlineChatBubbleOvalLeft,
  HiOutlinePencil,
  HiOutlinePlus,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance, lower, upper } from 'utils/date';
import formatDate from 'utils/format';
import AddOrganizationModal from './components/AddOrganizationModal';
import RemoveOrganizationModal from './components/RemoveOrganizationModal';

type Data = OrganizationsQuery['Organizations'][number];

function NameCell(props: CellContext<Data, unknown>) {
  const { row } = props;
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 size-10">
        <div className="size-10 mr-4 bg-primary-500 dark:bg-primary-700 focus:outline-none rounded-full shadow-md flex items-center justify-center text-white select-none capitalize">
          {row.original.Name[0]}
        </div>
      </div>
      <div className="ml-4 text-sm ">{row.original.Name}</div>
    </div>
  );
}

function ActionCell({
  row,
  setSelectedOrganization,
  setOpenRemoveModal,
  setOpenAddModal,
}: {
  row: Row<Data>;
  setSelectedOrganization: (user: Data | undefined) => void;
  setOpenRemoveModal: (open: boolean) => void;
  setOpenAddModal: (open: boolean) => void;
}): JSX.Element | null {
  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <Tooltip
          content={
            <p>
              <HiOutlineTrash
                onClick={() => {
                  setSelectedOrganization(row.original);
                  setOpenRemoveModal(true);
                }}
                data-test-id="remove-organization-button"
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
              />
            </p>
          }
        >
          <FormattedMessage id="Delete" />
        </Tooltip>
      </PrivateWrapper>
      <Tooltip
        content={
          <p>
            <HiOutlinePencil
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
              data-test-id="edit-organization-button"
              onClick={() => {
                setSelectedOrganization(row.original);
                setOpenAddModal(true);
              }}
            />
          </p>
        }
      >
        <FormattedMessage id="Edit" />
      </Tooltip>
    </div>
  );
}

function EmailCell({ mail }: { mail: string }) {
  return (
    <div className="flex space-x-1 items-center">
      <div>
        <HiOutlineChatBubbleOvalLeft className="size-5 dark:text-neutral-200" />
      </div>
      <div>
        <a className="hover:text-primary-600" href={`mailto:${mail}`}>
          {mail}
        </a>
      </div>
    </div>
  );
}

function LicenseCell({ row }: { row: Row<Data> }) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div
          className={`size-2.5 rounded-full ${
            row.original.LicenseExpired ? 'bg-red-500' : 'bg-green-500'
          } me-2`}
        />
        <div>
          {`${formatDate(lower(row.original.LicenseDuration))} - ${formatDate(
            upper(row.original.LicenseDuration),
          )}`}
        </div>
      </div>
      <div className="text-xs">
        {row.original.LicenseExpired ? (
          <FormattedMessage
            id="Expired"
            values={{
              duration: formattedDistance(
                new Date(upper(row.original.LicenseDuration)),
                { includeSeconds: true },
                new Date(),
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="Expires in"
            values={{
              duration: formattedDistance(
                new Date(upper(row.original.LicenseDuration)),
                { includeSeconds: true },
                new Date(),
              ),
            }}
          />
        )}
      </div>
    </div>
  );
}

export default function OrganizationsTable() {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<
    Data | undefined
  >(undefined);
  const [isAdding, setIsAdding] = useState(false);
  const intl = useIntl();
  const uuid = useDecodedLocation('uuid');
  const [{ data: organizations, fetching: loadingOrganizations }] =
    useOrganizationsQuery({
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_ORGANIZATIONS,
          ),
        [hasuraHeader, userRoles],
      ),
    });

  const columns: ColumnDef<Data>[] = [
    {
      accessorKey: 'Name',
      id: 'name',
      header: intl.formatMessage({ id: 'Organization' }),
      cell: NameCell,
    },
    {
      id: 'installedBeacons',
      header: intl.formatMessage({ id: 'Installed beacons' }),
      accessorFn: (row) =>
        (row.OnPremisesBeacons_aggregate.aggregate?.count ?? 0) +
        (row.MqttOrganizations.reduce(
          (acc, curr) =>
            acc + (curr.MqttBeacons_aggregate.aggregate?.count ?? 0),
          0,
        ) ?? 0),
    },
    {
      id: 'availableLicenses',
      header: intl.formatMessage({ id: 'Available licenses' }),
      accessorFn: (row) =>
        row.AvailableLicenses -
        ((row.OnPremisesBeacons_aggregate.aggregate?.count ?? 0) +
          (row.MqttOrganizations.reduce(
            (acc, curr) =>
              acc + (curr.MqttBeacons_aggregate.aggregate?.count ?? 0),
            0,
          ) ?? 0)),
      cell: ({ row }) =>
        `${
          row.original.AvailableLicenses -
          ((row.original.OnPremisesBeacons_aggregate.aggregate?.count ?? 0) +
            (row.original.MqttOrganizations.reduce(
              (acc, curr) =>
                acc + (curr.MqttBeacons_aggregate.aggregate?.count ?? 0),
              0,
            ) ?? 0))
        }/${row.original.AvailableLicenses}`,
    },
    {
      id: 'licenseDuration',
      header: intl.formatMessage({ id: 'License duration' }),
      accessorFn: (row) => new Date(lower(row.LicenseDuration)),
      cell: LicenseCell,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row.SalesContactEmail ?? '-',
      cell: ({ row }) =>
        row.original.SalesContactEmail
          ? EmailCell({
              mail: row.original.SalesContactEmail,
            })
          : '-',
      id: 'salesContact',
      header: intl.formatMessage({ id: 'Sales contact email' }),
    },
    {
      accessorFn: (row) => row.CustomerContactEmail ?? '-',
      cell: ({ row }) =>
        row.original.CustomerContactEmail
          ? EmailCell({ mail: row.original.CustomerContactEmail })
          : '-',
      id: 'customerContact',
      header: intl.formatMessage({ id: 'Customer contact email' }),
    },
    {
      accessorKey: 'Id',
      id: 'id',
      header: intl.formatMessage({ id: 'UUID' }),
    },
    {
      accessorFn: (row) =>
        row.MqttOrganizations.map((m) => m.UniqueIdentifier).join(', ') ?? '-',
      id: 'bluerangeUuid',
      header: intl.formatMessage({ id: 'Bluerange UUID' }),
    },
    {
      id: 'createdAt',
      header: intl.formatMessage({ id: 'Created at' }),
      accessorFn: (row) => new Date(row.CreatedAt),
      cell: ({ row }) => formattedDistance(new Date(row.original.CreatedAt)),
      enableColumnFilter: false,
    },
    {
      enableGrouping: false,
      id: ACTION_ID,
      cell: ({ row }) => (
        <ActionCell
          row={row}
          setSelectedOrganization={setSelectedOrganization}
          setOpenAddModal={setOpenAddModal}
          setOpenRemoveModal={setOpenRemoveModal}
        />
      ),
    },
  ];

  return (
    <>
      <Loader loading={loadingOrganizations} />
      <Table<Data>
        id="organizations"
        columns={columns}
        data={organizations?.Organizations ?? []}
        getRowId={(row) => row.Id}
        loading={loadingOrganizations}
        key={uuid}
        initialState={
          uuid
            ? {
                columnFilters: [{ id: 'bluerangeUuid', value: uuid }],
              }
            : {}
        }
        renderAdditionalAction={
          <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
            <Tooltip
              content={
                <StyledButton
                  onClick={() => {
                    setIsAdding(true);
                    setOpenAddModal(true);
                  }}
                >
                  <HiOutlinePlus
                    data-test-id="add-organization"
                    className="size-5"
                  />
                </StyledButton>
              }
            >
              <FormattedMessage id="Add organization" />
            </Tooltip>
          </PrivateWrapper>
        }
      />

      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <RemoveOrganizationModal
          organizationToRemove={selectedOrganization}
          setOrganizationToRemove={setSelectedOrganization}
          setOpen={setOpenRemoveModal}
          open={openRemoveModal}
        />
      </PrivateWrapper>

      <AddOrganizationModal
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        setOpen={setOpenAddModal}
        open={openAddModal}
      />
    </>
  );
}
