import { ExtendedMap } from '@/generic/components/BasicMap';
import BaseLayer from 'generic/layers/BaseLayer';
import GeometryLayer from 'generic/layers/GeometryLayer';
import { type BuildingsTopologyQuery, useFloorImageQuery } from 'graphql/types';
import Map from 'mda2-frontend/src/generic/components/Map';
import useStore from 'model/store';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'translations/Intl';
import getColor from 'utils/getColor';
import Description from '../../../Description';

interface ReportMapProps {
  floor: BuildingsTopologyQuery['Buildings'][number]['Floors'][number];
}

export default function ReportMap({ floor }: ReportMapProps) {
  const [baseLayer] = useState(new BaseLayer());
  const [roomLayer] = useState(
    new GeometryLayer<ReportMapProps['floor']['Rooms'][number]>({
      style: (feat) => [
        new Style({
          stroke: new Stroke({
            color: getColor('YELLOW', '.8'),
            width: 2,
          }),
          fill: new Fill({
            color: getColor('YELLOW', '.3'),
          }),
          text: new Text({
            text: `${feat.getProperties().Name} (${feat.getProperties().RoomType.Name})`,
            font: 'bold 16px "Inter Variable", sans-serif',
            fill: new Fill({ color: 'white' }),
          }),
        }),
      ],
    }),
  );
  const [map] = useState(new ExtendedMap());

  const building = useStore((state) => state.userSettings.building);

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorImageQuery(
    {
      variables: {
        BuildingName: building?.Name,
        FloorNumber: floor.Number,
      },
    },
  );

  useEffect(() => {
    if (floorImageData) {
      baseLayer.setImage(floorImageData.Floors[0]?.Image ?? '');
    }
  }, [
    baseLayer,
    floorImageData?.Floors[0]?.Image,
    floorImageData?.Floors.length,
    floorImageData,
  ]);

  useEffect(() => {
    if (floor.Rooms) {
      roomLayer.setFeatures(floor.Rooms);
    } else {
      roomLayer.setFeatures([]);
    }
  }, [floor.Rooms, roomLayer]);

  return (
    <>
      <Map
        className="h-48"
        map={map}
        enableZoomButton={false}
        layers={[baseLayer, roomLayer]}
        isLoadingFeatures={imageLoading}
      />
      <Description>
        <FormattedMessage
          id="Building Floor"
          values={{ building: building?.Name, number: floor.Number }}
        />
      </Description>
    </>
  );
}
