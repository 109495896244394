import Pill from '@/generic/components/Pill';
import { FormattedMessage, type IntlMessageKeys } from '@/translations/Intl';

interface OnlineCellProps {
  status: IntlMessageKeys;
}

export default function OnlineCell({ status }: OnlineCellProps) {
  return (
    <Pill
      className={`
          ${
            status === 'Online'
              ? '!bg-green-500 !text-green-100'
              : '!bg-red-500 !text-red-100'
          }
        `}
    >
      <FormattedMessage id={status} />
    </Pill>
  );
}
