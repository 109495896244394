import type { OccupancyMapQuery } from 'graphql/types';

import { lower, upper } from 'mda2-frontend/src/utils/date';
import getColor from 'mda2-frontend/src/utils/getColor';
import renderOfflineIcon from 'mda2-frontend/src/utils/renderOfflineIcon';
import renderReservedIcon from 'mda2-frontend/src/utils/renderReservedIcon';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type RoomInUseFeature = NonNullable<
  OccupancyMapQuery['f_live_rooms_occupancy']
>[number] & {
  LineInValue?: number;
  LineOutValue?: number;
  IsPersonal?: boolean;
};

export type RoomInUseFeatureType = TypedFeature<RoomInUseFeature, Geometry>;

const colorFunction = (
  feature: RoomInUseFeatureType,
  opacity: string,
  offline: boolean,
  isPrivate: boolean,
) => {
  if (offline) {
    return getColor('NEUTRAL400', opacity);
  }
  if (isPrivate) {
    return getColor('NEUTRAL600', opacity);
  }
  if (feature.getProperties().RoomOccupancy > 0)
    return getColor('RED', opacity);
  return getColor('GREEN', opacity);
};

const styleFunction = (
  feature: RoomInUseFeatureType,
  hoveredFeature: boolean,
  layer: RoomInUseLayer,
  map?: OLMap,
) => {
  const {
    RoomOccupancy: roomOccupancy,
    IsOffline: offline,
    IsPrivate: isPrivate,
    NextReservation: reservation,
    LineInValue: lineIn,
    LineOutValue: lineOut,
    IsPresenceSensor: isPresenceSensor,
    Capacity: capacity,
    IsPersonal: isPersonal,
  } = feature.getProperties();
  const showPeopleFlow = lineIn && lineOut && layer.get('showPeopleFlow');

  const lineFlowText = showPeopleFlow
    ? `${String.fromCharCode(0x2191)} ${lineIn} ${String.fromCharCode(
        0x2193,
      )} ${lineOut}`
    : '';

  const showRoomOccupancy = layer.get('showRoomOccupancy') && !isPresenceSensor;

  const getRoomOccupancyText = () => {
    let textToReturn = '';
    if (showRoomOccupancy) {
      const occupancy = roomOccupancy.toString();
      textToReturn = textToReturn.concat(`${occupancy}/${capacity}`);
    }
    if (showPeopleFlow) {
      // if already something in text, add line break
      if (textToReturn.length) {
        textToReturn = textToReturn.concat('\n');
      }
      textToReturn = textToReturn.concat(lineFlowText);
    }
    return textToReturn;
  };

  return [
    new Style({
      stroke: new Stroke({
        color: colorFunction(
          feature,
          hoveredFeature ? '1' : '.5',
          offline,
          isPrivate,
        ),
        width: hoveredFeature ? 2 : 1,
      }),
      fill: new Fill({
        color: colorFunction(
          feature,
          hoveredFeature ? '.7' : '.5',
          offline,
          isPrivate,
        ),
      }),
      text: offline
        ? undefined
        : map &&
          new Text({
            font: 'bold 12px sans-serif',
            fill: new Fill({
              color: 'white',
            }),
            scale: Math.abs(map.getView().getZoom() ?? 1),
            text: getRoomOccupancyText(),
          }),
    }),
    !reservation
      ? renderOfflineIcon(feature, offline, map)
      : renderReservedIcon(
          feature,
          !!reservation &&
            lower(reservation) < new Date() &&
            upper(reservation) > new Date(),
          map,
          isPersonal,
        ),
  ];
};

class RoomInUseLayer extends VectorLayer<Polygon> {
  features?: RoomInUseFeature[];

  hoveredFeature?: RoomInUseFeatureType;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(
              feature as RoomInUseFeatureType,
              feature.getGeometry() === this.hoveredFeature?.getGeometry(),
              this,
              this.map,
            ),
        }),
      },
      name: 'currentRoomInUse',
    });
  }

  setFeatures(feats: RoomInUseFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      RoomInUseLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: RoomInUseFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geo } = feat;
      return {
        type: 'Feature',
        geometry: geo,
        properties: {
          ...feat,
          isRoomFeature: true,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default RoomInUseLayer;
