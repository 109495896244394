import { FormattedMessage } from 'translations/Intl';

import { Tooltip } from '@visx/tooltip';
import { isSameDay } from 'date-fns';
import { RoomTypes } from 'mda2-frontend/src/common/types';
import Card from 'mda2-frontend/src/generic/components/Card';
import DurationMessage from 'mda2-frontend/src/generic/components/DurationMessage';
import StyledButton from 'mda2-frontend/src/generic/components/Form/Button/StyledButton';
import Map from 'mda2-frontend/src/generic/components/Map';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import PopupBody from 'mda2-frontend/src/generic/components/layout/PopupBody';
import BaseLayer from 'mda2-frontend/src/generic/layers/BaseLayer';
import DeskReservationLayer, {
  type DeskReservationFeature,
  type DeskReservationFeatureType,
} from 'mda2-frontend/src/generic/layers/DeskReservationLayer';
import type Layer from 'mda2-frontend/src/generic/layers/Layer';
import RoomReservationLayer, {
  type RoomReservationFeature,
  type RoomReservationFeatureType,
} from 'mda2-frontend/src/generic/layers/RoomReservationsLayer';
import {
  useFloorImageQuery,
  useFloorReservationsQuery,
} from 'mda2-frontend/src/graphql/types';
import defaultFloorplan from 'mda2-frontend/src/img/default_floorplan.jpg';
import useStore from 'mda2-frontend/src/model/store';
import { lower, upper } from 'mda2-frontend/src/utils/date';
import deskIsUsed from 'mda2-frontend/src/utils/deskIsUsed';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import useRoomDeskFilter from 'mda2-frontend/src/utils/graphql/useRoomDeskFilter';
import useDeviceDetect from 'mda2-frontend/src/utils/useDeviceDetect';
import type Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { touchOnly } from 'ol/events/condition';
import type Geometry from 'ol/geom/Geometry';
import type RenderFeature from 'ol/render/Feature';
import { useEffect, useState } from 'react';
import { HiOutlineLockClosed } from 'react-icons/hi2';

import { ExtendedMap } from '@/generic/components/BasicMap';
import {
  freeIcon,
  incognitoIcon,
  offlineIcon,
  usedIcon,
} from '../OccupancyMap/components/OccupancyPopup/OccupancyPopup';
import ReservationModal from '../ReservationModal';

const baseLayer = new BaseLayer();
const deskReservationLayer = new DeskReservationLayer();
const roomReservationLayer = new RoomReservationLayer();

function isDesk(
  toBeDetermined: RoomReservationFeatureType | DeskReservationFeatureType,
): toBeDetermined is DeskReservationFeatureType {
  if (
    (toBeDetermined as DeskReservationFeatureType).getProperties()
      .__typename === 'DeskReservations'
  ) {
    return true;
  }
  return false;
}

interface ReservationMapProps {
  reservationStartDate: Date;
  reservationEndDate: Date;
  className?: string;
}

function PopupMessage({
  feature,
}: {
  feature: DeskReservationFeatureType | RoomReservationFeatureType;
}) {
  const {
    Duration: isReservation,
    Reserved: isInUse,
    Offline: offline,
    IsPrivate: isPrivate,
  } = feature.getProperties();
  const start = isReservation ? lower(isReservation) : undefined;
  const end = isReservation ? upper(isReservation) : undefined;

  if (isPrivate) return <FormattedMessage id="Private room" />;
  if (isReservation && start && end) {
    if (offline) {
      return (
        <>
          <FormattedMessage id="The device may be offline" />
          <br />
          <DurationMessage start={start} end={end} />
        </>
      );
    }
    return <DurationMessage start={start} end={end} />;
  }

  if (offline) return <FormattedMessage id="The device may be offline" />;
  if (isInUse) return <FormattedMessage id="In use" />;
  return <FormattedMessage id="Free" />;
}

function ReservationPopupBody({
  hoveredFeature,
  isTouchedEvt,
  openReservationPopup,
  setHoveredFeature,
}: {
  hoveredFeature: RoomReservationFeatureType | DeskReservationFeatureType;
  isTouchedEvt: boolean;
  openReservationPopup: (feat: Feature<Geometry> | RenderFeature) => void;
  setHoveredFeature: (
    feat: RoomReservationFeatureType | DeskReservationFeatureType | null,
  ) => void;
}) {
  const renderIcon = () => {
    if (hoveredFeature.getProperties().IsPrivate) {
      return incognitoIcon;
    }
    if (hoveredFeature.getProperties().Reserved) {
      return usedIcon;
    }
    if (hoveredFeature.getProperties().Offline) {
      return offlineIcon;
    }

    return freeIcon;
  };
  return (
    <div className="flex flex-col">
      <PopupBody
        icon={renderIcon()}
        title={
          isDesk(hoveredFeature)
            ? hoveredFeature.getProperties().Desk.Name
            : hoveredFeature.getProperties().Room.Name
        }
      >
        <PopupMessage feature={hoveredFeature} />
        {isTouchedEvt && (
          <StyledButton
            className="my-2 w-fit"
            onClick={() => {
              openReservationPopup(hoveredFeature);
              setHoveredFeature(null);
            }}
          >
            <div className="flex items-center justify-center">
              <HiOutlineLockClosed className="mr-2 size-4" />
              <div>
                <FormattedMessage id="Reserve it" />
              </div>
            </div>
          </StyledButton>
        )}
      </PopupBody>
    </div>
  );
}

export default function ReservationMap({
  reservationStartDate,
  reservationEndDate,
  className,
}: ReservationMapProps): JSX.Element {
  const { isMobile } = useDeviceDetect();
  const userRoles = useStore((state) => state.user)?.roles;
  const buildingName = useStore((state) => state.userSettings.building)?.Name;
  const floor = useStore((state) => state.userSettings.floor);
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const selectedFeature = useStore((state) => state.selectedFeature);

  const [layers] = useState<Layer[]>([
    baseLayer,
    deskReservationLayer,
    roomReservationLayer,
  ]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [map] = useState(new ExtendedMap());
  const [hoveredFeature, setHoveredFeature] = useState<
    RoomReservationFeatureType | DeskReservationFeatureType | undefined | null
  >(null);
  const [clickedFeature, setClickedFeature] = useState<
    DeskReservationFeatureType | RoomReservationFeatureType | null
  >(null);
  const [isTouchedEvt, setIsTouchedEvt] = useState(false);
  const [openReservationModal, setOpenReservationModal] = useState(false);

  const [
    { data: reservations, fetching: loading },
    reexecuteReservationsQuery,
  ] = useFloorReservationsQuery({
    variables: {
      Start: reservationStartDate,
      End: reservationEndDate,
      FloorId: floor?.Id,
      ...useRoomDeskFilter(),
    },
    pause: !floor?.Id,
  });

  usePolling(loading, reexecuteReservationsQuery, 1000 * 30);

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorImageQuery(
    {
      variables: {
        BuildingName: buildingName,
        FloorNumber: floor?.Number,
      },
      pause: !buildingName || typeof floor?.Number !== 'number',
    },
  );

  useEffect(() => {
    if (floorImageData?.Floors[0]?.Image) {
      baseLayer.setImage(floorImageData.Floors[0].Image ?? '');
    }
    // Set a dummy room if there are no floors added yet
    if (floorImageData && floorImageData.Floors.length === 0) {
      baseLayer.setDefault(defaultFloorplan);
    }
  }, [floorImageData, floorImageData?.Floors[0]?.Image]);

  useEffect(() => {
    if (reservations?.DeskReservations && floorImageData?.Floors[0]?.Image) {
      if (roomTypes.includes(RoomTypes.DESKS)) {
        const features: DeskReservationFeature[] = [
          ...reservations.DeskReservations.map((reservation) => ({
            ...reservation,
            Reserved: true,
            Offline: reservation.Desk.Sensor?.MqttBeacon.IsOffline ?? false,
            IsPrivate: false,
          })),
          ...(reservations.Desks?.map((reservation) => ({
            __typename: 'DeskReservations' as const,
            Duration: undefined,
            Desk: {
              ...reservation,
            },
            Reserved:
              isSameDay(reservationStartDate, new Date()) &&
              !!deskIsUsed(
                reservation.Sensor?.UpdatedAt,
                reservation.Sensor?.Value ?? 0,
                reservation.Sensor?.IsPrivate ?? true,
              ),
            Offline: !!reservation.Sensor?.MqttBeacon.IsOffline,
            IsPrivate: !!reservation.Sensor?.IsPrivate,
          })) ?? []),
        ];
        deskReservationLayer.setFeatures(features);
      } else {
        deskReservationLayer.setFeatures([]);
      }
    }
  }, [
    floorImageData?.Floors[0]?.Image,
    reservationStartDate,
    reservations,
    reservations?.DeskReservations,
    roomTypes,
  ]);

  useEffect(() => {
    if (reservations?.RoomReservations && floorImageData?.Floors[0]?.Image) {
      if (roomTypes.includes(RoomTypes.MEETING)) {
        const features: RoomReservationFeature[] = [
          ...reservations.RoomReservations.map((reservation) => ({
            ...reservation,
            Reserved: true,
            Offline: reservation.Room.IsOffline ?? false,
            IsPrivate: false,
          })),
          ...(reservations.Rooms?.map((reservation) => ({
            __typename: 'RoomReservations' as const,
            Duration: undefined,
            Room: { ...reservation },
            Reserved: false,
            Offline: !!reservation.IsOffline,
            IsPrivate: reservation.RoomSensors.some((r) => r.Sensor.IsPrivate),
          })) ?? []),
        ];
        roomReservationLayer.setFeatures(features);
      } else {
        roomReservationLayer.setFeatures([]);
      }
    }
  }, [
    floorImageData?.Floors[0]?.Image,
    reservations,
    reservations?.RoomReservations,
    roomTypes,
  ]);

  const openReservationPopup = (feat: Feature<Geometry> | RenderFeature) => {
    if (userRoles?.includes(HasuraPermissions.VIEW_RESERVATION)) {
      setClickedFeature(
        feat as DeskReservationFeatureType | RoomReservationFeatureType,
      );
      setOpenReservationModal(true);
    }
  };

  const showFeatureInfo = (
    evt: MapBrowserEvent<PointerEvent>,
    feat?: RoomReservationFeatureType | DeskReservationFeatureType,
  ) => {
    if (feat) {
      setHoveredFeature(feat);
      if (isDesk(feat)) {
        deskReservationLayer.hoveredFeature = feat;
        deskReservationLayer.olLayer.changed();
      } else {
        roomReservationLayer.hoveredFeature = feat;
        roomReservationLayer.olLayer.changed();
      }
    } else {
      setHoveredFeature(null);
      deskReservationLayer.hoveredFeature = undefined;
      deskReservationLayer.olLayer.changed();
      roomReservationLayer.hoveredFeature = undefined;
      roomReservationLayer.olLayer.changed();
    }
    evt.map.getTargetElement().style.cursor =
      feat && !feat.getProperties().IsPrivate ? 'pointer' : '';
  };

  useEffect(() => {
    if (selectedFeature) {
      deskReservationLayer.olLayer.changed();
      roomReservationLayer.olLayer.changed();
    }
  }, [selectedFeature]);

  useEffect(() => {
    // Add a small delay as it won't be immediately fully visible after all data is available
    const timeout = setTimeout(() => {
      if (!loading && !imageLoading) {
        setDataLoaded(true);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [imageLoading, loading]);

  return (
    <Card className="relative" fullScreenButton>
      <Loader loading={loading || imageLoading} />
      <Map
        map={map}
        layers={layers}
        isLoadingFeatures={!dataLoaded}
        onFeaturesClick={(
          features: (DeskReservationFeatureType | RoomReservationFeatureType)[],
          evt,
        ) => {
          const [feat] = features;

          // Do not show modal for private
          if (feat?.getProperties().IsPrivate) {
            return;
          }
          // If touch screen click, open the hover Popup with an extra button to reserve
          if (touchOnly(evt)) {
            setIsTouchedEvt(true);
            showFeatureInfo(evt, feat);
          } else {
            setIsTouchedEvt(false);
            if (feat) openReservationPopup(feat);
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          if (isTouchedEvt) {
            setIsTouchedEvt(false);
          }
          const [feat] = hoveredFeatures.map((hF) => hF.feature);
          showFeatureInfo(evt, feat);
        }}
        renderTooltip={({
          top,
          left,
          className: popupClassName,
          'data-test-id': dataTestId,
        }) => {
          if (!hoveredFeature) return undefined;

          if (isMobile) {
            return (
              <Card className="absolute bottom-0 right-0 left-0 z-20">
                <ReservationPopupBody
                  hoveredFeature={hoveredFeature}
                  isTouchedEvt={isTouchedEvt}
                  openReservationPopup={openReservationPopup}
                  setHoveredFeature={setHoveredFeature}
                />
              </Card>
            );
          }

          // On touch there is a button -> it is a Popup and not a Tooltip, so we can't use Tooltip component
          if (isTouchedEvt) {
            return (
              <div
                style={{ top, left }}
                className={popupClassName}
                data-test-id={dataTestId}
              >
                <ReservationPopupBody
                  hoveredFeature={hoveredFeature}
                  isTouchedEvt={isTouchedEvt}
                  openReservationPopup={openReservationPopup}
                  setHoveredFeature={setHoveredFeature}
                />
              </div>
            );
          }

          return (
            <Tooltip
              top={top}
              left={left}
              data-test-id={dataTestId}
              className={popupClassName}
            >
              <ReservationPopupBody
                hoveredFeature={hoveredFeature}
                isTouchedEvt={isTouchedEvt}
                openReservationPopup={openReservationPopup}
                setHoveredFeature={setHoveredFeature}
              />
            </Tooltip>
          );
        }}
        className={className}
      />
      {clickedFeature && (
        <PrivateWrapper roleRequired={HasuraPermissions.VIEW_RESERVATION}>
          <ReservationModal
            feature={clickedFeature}
            open={openReservationModal}
            setOpen={setOpenReservationModal}
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
            onClose={() => {
              setClickedFeature(null);
              setOpenReservationModal(false);
            }}
          />
        </PrivateWrapper>
      )}
    </Card>
  );
}
